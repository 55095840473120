<template>
  <div class="icp">
    <span><a class="icp-link" href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024229911号</a></span>
  </div>
</template>

<script>
export default {
  name: "ICP"
}
</script>

<style scoped>
.icp {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 5%;
  min-height: 45px;
  white-space: pre;
  z-index: 1000;
  background-color:#ffffff;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.icp-link {
  color: #020007;
  text-decoration: none;
  font-size: 90%;
}
</style>
