<template>
  <div class="main-top">
    <ul>
      <li><a href="http://doricscm.cn" target="_blank" rel="noopener"><img src="@/assets/index-logo.svg" width="117px" height="45px"></a></li>
      <li><a href="http://doricscm.cn" target="_blank" rel="noopener">首页</a></li>
      <li><a href="" target="_blank" rel="noopener">物流服务</a></li>
      <li><a href="" target="_blank" rel="noopener">服务支持</a></li>
      <li><a href="" target="_blank" rel="noopener">解决方案</a></li>
      <li><a href="" target="_blank" rel="noopener">关于我们</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props: {
    msg: String
  }
}
</script>

<style scoped>
.main-top {
  position: fixed;
  right: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 5%;
  min-height: 45px;
  text-align: left;
  background-color: #ffffff; /* 不支持线性的时候显示 */
  /*background-image: linear-gradient(to right, Black, DarkSlateGray);*/
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #020007;
  text-decoration: none;
}
</style>
