<template>
  <div class="main-content">
    <img src="@/assets/eps-biz.svg" width="80%" height="90%" style="padding-top: 40px">
  </div>
</template>

<script>
export default {
  name: "MainContent"
}
</script>

<style scoped>
.main-content{
  position: absolute;
  top: 5%;
  right: 0;
  margin: 0px 0;
  width: 100%;
  height: 90%;
  background-color: #091a23;
}
</style>
